<template>
  <v-dialog
    persistent
    @keydown.esc="close"
    v-model="bDialogAddRawMaterial"
    :width="screenWidth + '%'"
  >
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-add-raw-material">
          <p class="title-add-raw-material">Nueva materia prima</p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-img">
          <div v-if="dataImg !== null" class="content-image">
            <v-btn @click="removeImg" icon class="button-delete-item">
              <v-icon size="20px"> mdi-delete </v-icon>
            </v-btn>
            <img :src="maskedFile(dataImg)" alt="" class="img-raw-material" />
          </div>
          <v-btn
            v-else
            id="btnAddImg"
            @click="$refs.inputUpload.click()"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
            class="button-add-img"
          >
            <div>
              <v-icon color="#B2B2B2" size="40px"> mdi-image-plus </v-icon>
              <p class="text-img-add">Arrastra ó da clic para añadir</p>
            </div>
            <input
              ref="inputUpload"
              style="display: none"
              type="file"
              size="60"
              accept=".jpg, .jpeg, .png"
              multiple="false"
              @change="uploadImg"
            />
          </v-btn>
        </div>
        <div class="content-text-fields">
          <v-text-field
            v-model="sCode"
            label="Clave"
            placeholder="Clave..."
            class="global-text-field mb-3"
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            persistent-placeholder
            outlined
            maxlength="20"
            @keyup="validateForm()"
          >
            <template slot="label">
              <span>Clave <span class="important-data">*</span></span>
            </template>
          </v-text-field>
          <v-text-field
            v-model="sName"
            label="Nombre"
            placeholder="Nombre..."
            class="global-text-field mb-3"
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            persistent-placeholder
            outlined
            maxlength="50"
            @keyup="validateForm()"
          >
            <template slot="label">
              <span>Nombre<span class="important-data">*</span></span>
            </template>
          </v-text-field>
          <v-text-field-currency
            v-model="dBasePurchasePriceMXN"
            class="mb-5"
            outlined
            label="Precio base compra (MXN)"
            placeholder="0.00"
            :bImportantData="true"
          />
          <v-text-field-currency
            v-model="dBasePurchasePriceUSD"
            class="mb-5"
            outlined
            label="Precio base compra (USD)"
            placeholder="0.00"
            :bImportantData="true"
            :options="oOptionCurrencyUSDGlobal"
          />
          <v-text-field-currency
            v-model="dBaseSalePriceMXN"
            class="mb-5"
            outlined
            label="Precio base venta (MXN)"
            placeholder="0.00"
            :bImportantData="true"
            @keyup="validateForm()"
          />
          <v-text-field-currency
            v-model="dBaseSalePriceUSD"
            class="mb-5"
            outlined
            label="Precio base venta (USD)"
            placeholder="0.00"
            :bImportantData="true"
            @keyup="validateForm()"
            :options="oOptionCurrencyUSDGlobal"
          />
          <v-text-field
            v-model="sColorKey"
            label="Color"
            placeholder="Color..."
            class="global-text-field cursor-pointer"
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            persistent-placeholder
            outlined
            @change="validateForm()"
            @keyup="validateForm()"
            @keypress="fieldHexadecimal($event)"
            maxlength="9"
          >
            <template slot="label">
              <span>Color <span class="important-data">*</span></span>
            </template>
            <template slot="append">
              <v-menu
                v-model="menuPickerColor"
                bottom
                min-width="200px"
                :close-on-content-click="false"
                offset-x
                rounded="lg"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="btn-icon-color-dynamic">
                    <v-icon
                      :style="{ color: sColorKey + '!important' }"
                      class="icon-color-dynamic c-pointer"
                    >
                      mdi-checkbox-blank-circle</v-icon
                    >
                  </div>
                  <v-btn icon v-on="on" class="btn-arrow-down-color">
                    <v-icon color="var(--primary-color-text)"
                      >mdi-chevron-down</v-icon
                    >
                  </v-btn>
                </template>
                <v-card class="content-picker-color-menu pa-2">
                  <div class="mx-auto">
                    <v-color-picker
                      v-model="sColorKey"
                      hide-inputs
                      dot-size="17"
                      mode="hexa"
                      swatches-max-height="200"
                    >
                    </v-color-picker>
                    <div class="content-btns-picker-color">
                      <v-btn
                        @click="menuPickerColor = !menuPickerColor"
                        class="global-btn-neutral"
                        >Cancelar</v-btn
                      >
                    </div>
                  </div>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </div>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-sidebar-global mb-3"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn
              class="global-btn-primary"
              :disabled="!bAdd"
              :loading="bLoading"
              @click="addRawMaterial()"
            >
              Añadir
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogAddRawMaterial: Boolean,
  },
  data() {
    return {
      screenWidth: 0,
      bAdd: false,
      bLoading: false,
      dataImg: null,
      sCode: "",
      sName: "",
      sColorKey: "#FFFFFFFF",
      dBasePurchasePriceMXN: null,
      dBasePurchasePriceUSD: null,
      dBaseSalePriceMXN: null,
      dBaseSalePriceUSD: null,
      types: ["hex"],
      type: "hex",
      menuPickerColor: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 60;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 40;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 30;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 25;
      }
    },
    uploadImg: function (e) {
      const files = e.target.files;
      this.dataImg = files;
      this.validateForm();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.dataImg = event.dataTransfer.files;
      this.validateForm();

      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    removeImg: function () {
      this.dataImg = null;
      this.$nextTick(() => {
        var btnAddImg = document.getElementById("btnAddImg");
        if (btnAddImg) {
          btnAddImg.classList.add("button-add-img-required");
        }
      });
      this.validateForm();
    },
    maskedFile: function (file) {
      return URL.createObjectURL(file[0]);
    },
    close() {
      this.$emit("setDialogAddRawMaterial");
      var btnAddImg = document.getElementById("btnAddImg");
      if (btnAddImg) {
        btnAddImg.classList.remove("button-add-img-required");
      }
      this.bAdd = false;
      this.bLoading = false;
      this.dataImg = null;
      this.sCode = "";
      this.sName = "";
      this.sColorKey = "#FFFFFFFF";
      this.dBasePurchasePriceMXN = null;
      this.dBasePurchasePriceUSD = null;
      this.dBaseSalePriceMXN = null;
      this.dBaseSalePriceUSD = null;
    },
    addRawMaterial() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminMaterial) {
            const payload = {
                sCode: this.sCode,
                sName: this.sName,
                sMeasurementUnitId: "75f72e59-d1d5-4fbd-803a-d9166c02f66d",
                sColorKey: this.sColorKey.replace("#", ""),
                aPrices: this.setFillListPrices(),
              },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };

            DB.post(`${URI}/api/sp/v1/raw-materials`, payload, config)
              .then((response) => {
                this.addImgRawMaterial(
                  response.data.results.sRawMaterialId,
                  response.data.message,
                  response.data.results.sName
                );
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    setFillListPrices() {
      let aPrices = [
        {
          sCurrencyId: "ca3bf61e-6fb1-4ff3-adfd-1cb5c6b99c48",
          dAmount: Number(this.dBaseSalePriceUSD),
          sType: "sales",
        },
        {
          sCurrencyId: "ca3bf61e-6fb1-4ff3-adfd-1cb5c6b99c48",
          dAmount: Number(this.dBasePurchasePriceUSD),
          sType: "purchase",
        },
        {
          sCurrencyId: "cf37a729-0384-4ae0-8be8-60575e60c603",
          dAmount: Number(this.dBaseSalePriceMXN),
          sType: "sales",
        },
        {
          sCurrencyId: "cf37a729-0384-4ae0-8be8-60575e60c603",
          dAmount: Number(this.dBasePurchasePriceMXN),
          sType: "purchase",
        },
      ];
      return aPrices;
    },
    addImgRawMaterial(id, Message, Name) {
      let FullName = Name;
      let MessageSuccess = Message;
      let form = new FormData();
      form.append("aImage", this.dataImg[0]);

      const config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      };

      DB.patch(`${URI}/api/sp/v1/raw-materials/${id}`, form, config)
        .then((response) => {
          this.bLoading = false;
          this.$store.commit("refresher", true);
          this.close();
          this.Success(MessageSuccess, FullName);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },

    validateForm() {
      if (this.dataImg == null) {
        var btnAddImg = document.getElementById("btnAddImg");
        if (btnAddImg !== null) {
          btnAddImg.classList.add("button-add-img-required");
        }
      } else {
        var btnAddImg = document.getElementById("btnAddImg");
        if (btnAddImg !== null) {
          btnAddImg.classList.remove("button-add-img-required");
        }
      }
      this.bAdd =
        this.dataImg !== null &&
        this.sCode.trim() !== "" &&
        this.sName.trim() !== "" &&
        this.dBasePurchasePriceMXN > 0 &&
        this.dBasePurchasePriceUSD > 0 &&
        this.dBaseSalePriceMXN > 0 &&
        this.dBaseSalePriceUSD > 0 &&
        this.sColorKey.trim() !== "";
    },
    fieldHexadecimal(evt) {
      if (
        !(
          (evt.keyCode >= 48 && evt.keyCode <= 57) ||
          (evt.keyCode >= 65 && evt.keyCode <= 90) ||
          (evt.keyCode >= 97 && evt.keyCode <= 122)
        )
      ) {
        evt.preventDefault();
      }
      // if (
      //   (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
      //   evt.which > 91
      // ) {
      //   evt.preventDefault();
      // }
    },
    changeBasePurchasePrice(dBasePurchasePriceMXN) {
      if (dBasePurchasePriceMXN !== "") {
        this.dBasePurchasePriceMXN = parseFloat(dBasePurchasePriceMXN);
      } else {
        this.dBasePurchasePriceMXN = 0;
      }
    },
    changeBaseSalePrice(dBaseSalePriceMXN) {
      if (dBaseSalePriceMXN !== "") {
        this.dBaseSalePriceMXN = parseFloat(dBaseSalePriceMXN);
      } else {
        this.dBaseSalePriceMXN = 0;
      }
    },
  },
  computed: {
    // bDialogAddRawMaterial() {
    //   return this.$store.state.bDialogAddRawMaterial;
    // },
    bShowMaterial() {
      return this.$store.state.bShowMaterial;
    },
    bAdminMaterial() {
      return this.$store.state.bAdminMaterial;
    },
  },
  watch: {
    dBaseSalePriceMXN() {
      this.validateForm();
    },
    dBaseSalePriceUSD() {
      this.validateForm();
    },
    dBasePurchasePriceMXN() {
      this.validateForm();
    },
    dBasePurchasePriceUSD() {
      this.validateForm();
    },
    sColorKey() {
      if (!this.sColorKey.includes("#")) {
        this.sColorKey = this.sColorKey.trim().replace("#", "");
        this.sColorKey = "#" + this.sColorKey;
      }
      this.sColorKey = this.sColorKey.toUpperCase();

      this.validateForm();
    },
    // dialogDelete() {
    //   if (this.dialogDelete) {
    //     this.title = "sii";
    //   }
    // },
  },
};
</script>
<style scoped>
.btn-icon-color-dynamic {
  border: solid var(--primary-color-text) 1px;
  border-radius: 100%;
  margin-top: -5px;
  margin-bottom: 5px;
  height: 26px;
  width: 26px;
}

.icon-color-dynamic {
  width: 100%;
  height: 100%;
}

.btn-arrow-down-color {
  margin-top: -10px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.content-title-add-raw-material {
  width: 100%;
}

.content-img {
  margin-bottom: 25px;
  justify-content: center;
  display: flex;
}

.content-image {
  width: 60%;
  height: 200px !important;
  position: relative;
  border: 1px solid #70707080;
  border-radius: 20px;
}

.img-raw-material {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5%;
  border: var(--primary-color-border-text-field) solid 3px;
}

.button-delete-item {
  position: absolute;
  right: 15px;
  bottom: 5px;
  min-width: 30px !important;
  height: 30px !important;
  background-color: var(--primary-color-btn-neutral-red) !important;
  padding: 0px !important;
  border-radius: 10px;
}

.button-edit-item {
  position: absolute;
  right: 15px;
  bottom: 5px;
  min-width: 30px !important;
  height: 30px !important;
  background-color: var(--primary-color-btn-primary) !important;
  padding: 0px !important;
  border-radius: 10px;
}

.button-add-img {
  width: 60% !important;
  height: 200px !important;
  /* background: transparent 0% 0% no-repeat padding-box !important; */
  background: radial-gradient(
    var(--primary-color-background-button-upload-image),
    transparent
  ) !important;
  border: 2px dashed var(--primary-color-text);
  border-radius: 20px;
  opacity: 1;
}

.button-add-img-required {
  width: 60% !important;
  height: 200px !important;
  /* background: transparent 0% 0% no-repeat padding-box !important; */
  background: radial-gradient(
    var(--primary-color-background-button-upload-image),
    transparent
  ) !important;
  border: 2px dashed var(--primary-color-text-red) !important;
  border-radius: 20px;
  opacity: 1;
}

.text-img-add {
  color: var(--primary-color-text) !important;
  font-family: "pop-Semibold";
  font-size: 9px;
  text-transform: initial;
}

.title-add-raw-material {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
  word-break: normal;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}

.content-btn-primary {
  width: 100%;
}

.content-btn-second {
  width: 100%;
  margin-right: 15px;
}

.content-btns-picker-color {
  width: 50%;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .text-img-add {
    color: var(--primary-color-text) !important;
    font-family: "pop-Semibold";
    font-size: 6px;
    text-transform: initial;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
